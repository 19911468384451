<template>
  <div id="krave-works-list">
    <div class="tags text-center">
      <div class="mx-auto py-2 text-center krave-tags-div">
        <a v-for="(tag, index) in tags" :key="index" class="krave-tag px-3" @click="setTags(tag)">{{ tag }}</a>
      </div>
    </div>
    <div id="krave-images">
      <div v-if="works">
        <div v-masonry class="masonry-main-div" origin-left="true" transition-duration=".4s" stagger="0" gutter="0" resize="true" item-selector=".masonry-item">
          <div v-masonry-tile class="masonry-tile" v-for="item in works" :key="item.index">
            <div class="masonry-item masonry-div">
              <transition name="fade" mode="out-in">
                <div class="masonry-content-div d-flex flex-column w-100">
                  <div class="masonry-div-img overflow-hidden position-relative">
                    <lazy-img :item="item"></lazy-img>
                    <div class="position-absolute background-for-img"></div>
                  </div>
                  <div class="masonry-div-title">
                    <router-link :to="{ name: 'Home', params: { work: item.index.toString() }, }" class="indexLink">
                      <h5 class="masonry-title-text text-white px-5">
                        {{ item.txtInfo }}
                      </h5>
                    </router-link>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkLists",
  computed: {
    works() { return this.$store.getters.works },
    tags() { return this.$store.getters.tags }
  }
};
</script>

<style scoped>
@import "../css/WorkLists.scss";
</style>
