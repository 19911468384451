<template>
  <div class="w-100" v-if="gallery">
    <div v-masonry origin-left="true" class="row" transition-duration=".3s" stagger="0" resize="true" item-selector=".masonry-item">
      <div v-masonry-tile v-for="(img, ix) of gallery" :key="ix" class="border-light">
        <div class="masonry-item masonry-div col-12 col-sm-12 col-md-6 col-lg-6 p-2">
          <img :src="img.link" :alt="img.alt || ''" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageGallery',
  props: [ 'gallery' ],
  created() {
    // console.log('gallery: ', this.gallery);
  }
}
</script>

<style scoped>

</style>
