<template>
  <img :src="img.link" loading="lazy" :alt="img.alt || ''" class="img-fluid w-75">
</template>

<script>
export default {
  name: 'Image',
  props: [ 'img' ]
}
</script>

<style scoped>

</style>
