<template>
  <div v-html="html.html"></div>
</template>

<script>
export default {
  name: 'Html',
  props: [ 'html' ]
}
</script>

<style scoped>

</style>
