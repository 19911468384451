<template>
  <div class="container-fluid" v-if="works">
    <h1 class="mainTitle h1 text-center p-5">{{ works.txtInfo }}</h1>
    <div v-for="(prod, idx) in works.product" :key="idx" class="p-2">
      <div v-if="prod.type === 'video'" class="p-2 w-100 d-flex justify-content-center align-items-center">
        <div width="100%" class="w-75 mx-auto" v-for="(vid, ix) in prod.module" :key="ix">
          <Vimeo :css="vid.embed" :type="vid.video" :src="vid.link"></Vimeo>
        </div>
      </div>
      <div v-if="prod.type === 'image'">
        <vImage v-for="(img, ix) in prod.module" :key="ix" class="p-2" :img="img"></vImage>
      </div>
      <div v-if="prod.type === 'gallery'">
        <ImageGallery :gallery="prod.module"></ImageGallery>
      </div>
      <div v-if="prod.type === 'html'">
        <Html v-for="(html, ix) in prod.module" :key="ix" class="p-2" :html="html"></Html>
      </div>
      <div v-if="prod.type === 'block'">
        <div v-for="(bl, ix) of prod" :key="ix">
          <div v-if="bl.type === 'video'" class="p-2 w-100 d-flex justify-content-center align-items-center">
            <div width="100%" class="w-75 mx-auto" v-for="(vid, ix) in bl.module" :key="ix">
              <Vimeo :css="vid.embed" :type="vid.video" :src="vid.link"></Vimeo>
            </div>
          </div>
          <div v-if="bl.type === 'html'">
            <Html v-for="(html, ix) in bl.module" :key="ix" class="p-2" :html="html"></Html>
          </div>
          <div v-if="bl.type === 'image'">
            <vImage v-for="(img, ix) in bl.module" :key="ix" class="p-2" :img="img"></vImage>
          </div>
        </div>
      </div>
      <div v-if="!( prod.type === 'video' || prod.type === 'block' || prod.type === 'html' || prod.type === 'image' || prod.type === 'gallery' )" class="p-2 w-100 d-flex justify-content-center align-items-center">
        {{ prod }}
      </div>
    </div>
    <div class="mx-auto text-center">
      <div v-for="(tags, index) in works.tags" :key="index" class="d-inline-block py-3">
        <span class="text-uppercase h5 slugName" :theKey="index">
          {{ tags }}
        </span>
        <span class="between px-3" v-if="index < works.tags.length - 1">/</span>
      </div>
    </div>
  </div>
</template>

<script>
import vImage from "@/components/Image";
import Vimeo from "@/components/Vimeo";
import Html from "@/components/Html";
import ImageGallery from "@/components/ImageGallery"

export default {
  name: "WorkElement",
  props: [ "work" ],
  data() {
    return {
      works: [],
    };
  },
  async mounted() {
    // console.log( 'WorkElement works: ', this.work );
    this.works = this.$store.getters.getWork( this.work )[0];
    // console.log( 'WorkElement mounted works: ', this.works )
    if ( !this.works ) {
      this.$store.dispatch( 'getWorks' ).then(
          resp => {
            // console.log('getWorks end!', resp );
            if ( resp )
              this.works = this.$store.getters.getWork(this.work)[0];
            else
              this.$router.push({ name: 'Home' })
          }
      )
    }
  },
  methods: {
  },
  components: {
    vImage,
    Vimeo,
    Html,
    ImageGallery
  },
};
</script>

<style scoped>
h1.mainTitle {
  font-size: 50px;
  font-weight: 100;
  font-family: "Lato", Lato, "Ubuntu", sans-serif;
  color: #222324;
}
.slugName {
  font-size: 18px;
  font-weight: 300;
  font-family: "Lato", Lato, "Ubuntu", sans-serif;
  color: #222324;
  opacity: 0.8;
}
.between {
  font-size: 18px;
  font-weight: 100;
  font-family: "Lato", Lato, "Ubuntu", sans-serif;
  color: #222324;
  opacity: 0.6;
}
</style>
