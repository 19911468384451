<template>
  <div id="home-page" class="w-100 mw-100 px-0 pt-4 pb-5">
    <template  v-if="$route.params.work === undefined">
      <WorkLists></WorkLists>
    </template>
    <template v-else>
      <WorkElement :work="$route.params.work"></WorkElement>
    </template>
  </div>
</template>

<script>
import WorkLists from "@/components/WorkLists";
import WorkElement from "@/components/WorkElement";

export default {
  name: "Home",
  components: {
    WorkLists,
    WorkElement,
  }
};
</script>
<style scoped></style>
