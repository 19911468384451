<template>
  <div class="p-3" v-if="valid">
    <div class="embed-responsive" :class="[css]">
      <iframe :src="url" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    css: {
      type: String,
      default: 'embed-responsive-16by9'
    },
    src: { type: String },
    type: { type: String },
    params: {
      type: Object
    },
  },
  data() {
    return {
      valid: false,
      url: '',
      videos: [
        {
          //reg: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/i,
          //reg: /^.*(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i,
          reg: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/i,
          url: 'https://www.youtube.com/embed/$5',
          params: {
            'picture-in-picture': 1,
            accelerometer: 1,
            gyroscope: 1
          }
        }, {
          reg: /^.*vimeo.com\/video\/(\d+)($|\/|\b)/i,
          url: 'https://player.vimeo.com/video/$1',
          params: {
            title: 0,
            byline: 0,
            portrait: 0
          },
        }, {
          reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
          url: 'https://www.dailymotion.com/embed/video/$1',
          params: {
            autoplay: 0
          }
        }, {
          reg: /^.*coub.com\/(?:embed|view)\/([A-Za-z0-9]+)([^#\&\?]*).*/i,
          url: 'https://coub.com/embed/$1',
          params: {
            autoplay: 0
          }
        },
        // {
        //     reg: /^.*twitch.tv\/videos\/(\d+)($|\/|\b)/i,
        //     url: 'https://player.twitch.tv/?autoplay=false&video=v$1',
        //     params: {
        //         autoplay: false
        //     },
        //     callback: function () {
        //         return 'https://player.twitch.tv/?video=v$1';
        //     }
        // }
      ],
    }
  },
  watch: {
    src(val) {
      this.parse();
    },
  },
  methods: {
    parse() {
      if (this.src) {
        // console.log('this src: ', this.src);
        // console.log('this.type: ', this.type);
        if (this.type === 'vimeo') {
          const params = Object.assign({}, this.videos[1].params, this.params);
          const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
          const and =  this.videos[1].url.indexOf('?') >= 0 ? '&' : '?';
          // console.log('vimeo this.params: ', params, ', query: ', query, ', and: ', and);
          this.url = this.src + and + query;
        } else if (this.type === 'youtube') {
          const params = Object.assign({}, this.videos[1].params, this.params);
          const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
          const and = this.videos[0].url.indexOf('?') >= 0 ? '&' : '?';
          // console.log('youtube this.params: ', params, ', query: ', query, ', and: ', and);
          this.url = this.src.replace(this.videos[0].reg, this.videos[0].url) + and + query;
        }
        this.valid = true;
        // console.log('this.url: ', this.url);
        return;
        /*
<iframe title="vimeo-player" src="https://player.vimeo.com/video/394147778?h=1fded26594" width="640" height="337" frameborder="0" allowfullscreen></iframe>
<iframe title="vimeo-player" src="https://player.vimeo.com/video/352818884?h=9eaf561f91" width="640" height="360" frameborder="0" allowfullscreen></iframe>
*/
        /*for (let i = 0; i < this.videos.length; i++) {
          const v = this.videos[i];
          var m = v.reg.exec(this.src);
          if (m) {
            var params = Object.assign({}, v.params, this.params);
            var query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            var and = v.url.indexOf('?') >= 0 ? '&' : '?';
            this.url = this.src + and + query;
            this.valid = true;
            console.log("parse url: ". this.url);
            return;
          }
        }*/
      }
      this.valid = false;
    },
  },
  mounted() {
    this.parse();
  }
};
</script>
